<template>
  <div>
    <div class="bw">
      <v-container class="p-0">
        <v-row class="h97">
          <v-col cols="6" class="d-flex">
            <a href="/" class="align-self-center">
              <img
                src="@/assets/Einhell_logo.jpg"
                class="img img-fluid logo"
                alt="ТОВ &amp;quot; ХАНС АЙНХЕЛЬ УКРАЇНА&amp;quot;"
              />
            </a>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="navbar">
      <v-container class="p-0">
        <v-app-bar class="navbar">
          <v-app-bar-nav-icon @click="drawer = true" class="d-flex d-sm-none">
            <v-btn plain class="ml-11">
              <v-icon>mdi-menu</v-icon>
              Меню
            </v-btn>
          </v-app-bar-nav-icon>
          <v-tabs
            v-model="tab"
            align-with-title
            class="d-none d-sm-flex tab-no-lm"
            hide-slider
            color="white"
          >
            <v-tab ripple class="text-decoration-underline font-weight-bold">
              Зареєструвати гарантію
            </v-tab>
            <v-tab ripple class="text-decoration-underline font-weight-bold">
              Пошук Сервіс-центрів
            </v-tab>
          </v-tabs>
        </v-app-bar>
        <!-- Add a navigation bar -->
        <v-navigation-drawer v-model="drawer" absolute temporary>
          <v-list nav dense color="white">
            <v-list-item-group>
              <v-list-item>
                <v-list-item-title
                  @click="
                    tab = 0;
                    drawer = false;
                  "
                >
                  Зареєструвати гарантію
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title
                  @click="
                    tab = 1;
                    drawer = false;
                  "
                >Пошук Сервіс-центрів
                </v-list-item-title
                >
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-container>
    </div>
    <v-main>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card>
            <v-container fill-width pb-10 pl-5 pr-5 class="">
              <h1 class="pt-10">Реєстрація гарантії</h1>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row class="mt-6">
                  <v-col cols="4" class="ml-0 pl-0 pt-0 pb-0">
                    <v-subheader class="ml-0 pl-0" style="margin-top: 20px"
                    >Артикул виробу
                    </v-subheader
                    >
                  </v-col>
                  <v-col cols="8" class="pt-0 pb-0">
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <div class="v-messages__message text-right hidden-txt">
                            <span class="hidden-txt" @click="handleExampleShow($event)">
                              <v-icon @click="handleIconExampleShow($event)">
                                mdi-information-outline</v-icon
                              >
                              Приклад Артикулу
                            </span>
                            <span class="hidden-img" @click="handleExampleHide($event)">
                              <img src="@/assets/artikel.jpg" width="250" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <v-text-field
                      single-line
                      outlined
                      dense
                      :color="inputColor(model)"
                      :class="inputClass(model)"
                      v-model="artikul"
                      :rules="artikulRules"
                      @input.native="artikulInputMask"
                      clearable
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4" class="ml-0 pl-0 pt-0 pb-0">
                    <v-subheader class="ml-0 pl-0">Модель виробу</v-subheader>
                  </v-col>
                  <v-col cols="8" class="pt-0 pb-0">
                    <v-text-field
                      single-line
                      outlined
                      dense
                      disabled
                      placeholder="Заповніть поле Артикул"
                      v-model="model"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="4" class="ml-0 pl-0 pt-0 pb-0">
                    <v-subheader class="ml-0 pl-0">Дата купівлі</v-subheader>
                  </v-col>
                  <v-col cols="8" class="pt-1 pb-7">
                    <date-picker
                      v-model="buyDate"
                      format="DD/MM/YYYY"
                      :editable="false"
                      :clearable="false"
                      ref="datepicker"
                      :input-class="DatepickerInputClass"
                      @input="dateValid = true"
                      :disabled-date="disabledRange"
                    >
                      <template v-slot:icon-calendar>
                        <v-icon></v-icon>
                      </template>
                    </date-picker>
                    <div
                      v-if="!dateValid"
                      class="v-messages theme--light error--text pt-1"
                      role="alert"
                    >
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message">Оберіть дату купівлі!</div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="4" class="ml-0 pl-0 pt-0 pb-0">
                    <v-subheader class="ml-0 pl-0">Прізвище Ім'я Побатькові</v-subheader>
                  </v-col>
                  <v-col cols="8" class="pt-0 pb-0">
                    <v-text-field
                      single-line
                      outlined
                      dense
                      v-model="fio"
                      :rules="fioRules"
                      clearable
                      :color="inputColor(this.fio)"
                      :class="inputClass(this.fio)"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4" class="ml-0 pl-0 pt-0 pb-0">
                    <v-subheader class="ml-0 pl-0">Контактний телефон</v-subheader>
                  </v-col>
                  <v-col cols="8" class="pt-0 pb-0">
                    <v-text-field
                      single-line
                      outlined
                      dense
                      v-model="tel"
                      :rules="telRules"
                      clearable
                      :color="inputColorAndClassTel.color"
                      :class="inputColorAndClassTel.class"
                      v-mask="'+38(0##)#######'"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4" class="ml-0 pl-0 pt-0 pb-0">
                    <v-subheader class="ml-0 pl-0">E-mail</v-subheader>
                  </v-col>
                  <v-col cols="8" class="pt-0 pb-0">
                    <v-text-field
                      single-line
                      outlined
                      dense
                      v-model="email"
                      :rules="emailRules"
                      clearable
                      :color="inputColorAndClassEmail.color"
                      :class="inputColorAndClassEmail.class"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="4" class="ml-0 pl-0 pt-0 pb-0">
                    <v-subheader class="ml-0 pl-0">Продавець згідно касового чеку</v-subheader>
                  </v-col>
                  <v-col cols="8" class="pt-0 pb-0">
                    <v-text-field
                      single-line
                      outlined
                      dense
                      v-model="seller"
                      :rules="sellerRules"
                      clearable
                      :color="inputColor(this.seller)"
                      :class="inputClass(this.seller)"
                    ></v-text-field>
                  </v-col>

                  <div class="v-text-field__details">
                    <div class="v-messages theme--light pr-3">
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message text-right hidden-txt">
                          <span class="hidden-txt" @click="handleExampleShow($event)">
                            <v-icon @click="handleIconExampleShow($event)">
                              mdi-information-outline
                            </v-icon>
                            Приклад Серійного номеру
                          </span>
                          <span class="hidden-img" @click="handleExampleHide($event)">
                            <img src="@/assets/serial_number.jpg" width="250" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <component
                    v-for="(component, index) in componentsArray"
                    :key="index"
                    :is="component"
                    v-model="serials[index].Serial"
                    :allowDelete="allowDelete"
                    :index="index"
                    @deleteSelf="handleDestroy"
                  />

                  <v-col cols="12" sm="12" class="pt-0 pb-0">
                    <v-btn text block @click="add">Додати серійний номер</v-btn>
                  </v-col>
                  <div class="d-flex pt-5" style="width: 100%">
                    <v-col cols="4" class="ml-0 pl-0 pt-0 pb-0">
                      <v-subheader class="ml-0 pl-0">Де ви придбали товар ?</v-subheader>
                    </v-col>
                    <v-col cols="8" class="pt-0 pb-0">
                      <v-select
                        dense
                        single-line
                        outlined
                        :items="buyPlaces"
                        item-value="Id"
                        item-text="Name"
                        v-model="buyPlace"
                        :color="inputColor(buyPlace)"
                        :class="inputClass(buyPlace)"
                        :rules="buyPlacesRules"
                      >
                      </v-select>
                    </v-col>
                  </div>
                  <div class="d-flex pt-5" style="width: 100%">
                    <v-col cols="6" class="ml-0 pl-0 pt-0 pb-0">
                      <v-checkbox
                        v-model="dataSecurityInfo"
                        :rules="[(v) => !!v || 'Ви повинні дати згоду щоб продовжити!']"
                      >
                        <template v-slot:label>
                          <div>
                            Я ознайомився з
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <a target="_blank" href="/data_security_info" @click.stop v-on="on">
                                  інформацією про захист даних
                                </a>
                              </template>
                              Відкриєтся у новому вікні
                            </v-tooltip>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="6" class="ml-0 pl-0 pt-0 pb-0">
                      <v-checkbox
                        v-model="warrantySpecInfo"
                        :rules="[(v) => !!v || 'Ви повинні дати згоду щоб продовжити!']"
                      >
                        <template v-slot:label>
                          <div>
                            Я ознайомився з
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <a target="_blank" href="/warranty_spec_info" @click.stop v-on="on">
                                  інформацією про умови гарантії
                                </a>
                              </template>
                              Відкриєтся у новому вікні
                            </v-tooltip>
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </div>
                </v-row>
                <v-row>
                  <v-col cols="12" class="ml-0 pl-0 pt-0 pb-0 d-flex justify-center align-center">
                    <v-btn class="" :disabled="!valid || !dateValid" @click="DoRequest"
                    >Зареєструвати гарантію
                    </v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-container fluid fill-width pb-10>
              <v-row>
                <v-col cols="12" lg="2">
                  <h4>Авторизовані сервісні партнери (АСП):</h4>
                  <ul>
                    <li class="mt-2">
                      <p>Київ, вул. Марка Вовчка, 18а</p>
                      <p>тел.: 0800-030-03-45</p>
                      <p>Графік роботи: Пн - Пт 09:00 - 18:00</p>
                      <p>УВАГА! БЕНЗОТЕХНІКА НЕ ОБСЛУГОВУЄТЬСЯ</p>
                    </li>
                    <li>
                      <p>Чернігів, вул. Реміснича, 49</p>
                      <p>тел.: 093-440-94-42, 068-339-82-12</p>
                      <p>Графік роботи: Вт-Нд 08:00-16:00</p>
                    </li>
                    <li>
                      <p>Одеса, пров. Волзький, 3</p>
                      <p>тел.: 063-227-04-34</p>
                      <p>Графік роботи: Пн-Пт 09:00-16:00</p>
                    </li>
                    <li>
                      <p>Хмельницький, Старокостянтинівське шосе, 26/9Б</p>
                      <p>тел.: 067-261-12-13, 063-946-24-85, 066-402-78-76</p>
                      <p>Графік роботи: Пн-Пт 09:00-18:00</p>
                    </li>
                    <li>
                      <p>Рівне, вул. Біла, 16</p>
                      <p>тел.: 067-762-38-37</p>
                    </li>
                    <li>
                      <p>Заболотів, площа Бандери, 1</p>
                      <p>тел.: 098-950-77-50</p>
                      <p>Графік роботи: Пн-Пт з 09:00 до 18:00, Сб з 09:00 до 15:00</p>
                    </li>
                    <li>
                      <p>Львів, вул. Зелена 238 (територія ДБК)</p>
                      <p>тел.: 098-717-31-20</p>
                      <p>Графік роботи: Пн-Пт з 10:00 до 18:00</p>
                    </li>
                    <li>
                      <p>Трускавець, вул. Стебницька 47/1</p>
                      <p>Тел.: 068-155-98-00</p>
                      <p>Графік роботи: Пн-Пт 09:00-18:00, Сб 09:00-14:00</p>
                    </li>
                    <li>
                      <p>Самбір, вул. Завокзальна 1Г</p>
                      <p>Тел.: 096-782-33-73, 032-367-28-78</p>
                      <p>Графік роботи: Пн-Пт 09:00-18:00, Сб: 09:00-13:00</p>
                    </li>
                    <p>
                      <b>
                        Якщо у вашому населеному пункті немає АСП або вага вашого пристрою ТМ
                        EINHELL більше 5 кг, надсилайте ваш пристрій Новою Поштою до нашого пункту
                        прийому для передачі в сервісний центр.
                      </b>
                    </p>
                    <p>
                      <b> Реквізити пункту прийому Новою Поштою: </b>
                    </p>
                    <p>
                      <b> ЄДРПОУ 38275500 </b>
                    </p>
                    <p>
                      <b> юридична особа ТОВ "Ханс Айнхель Україна" </b>
                    </p>
                    <p>
                      <b>
                        Київська обл., Бучанський р-н., с. Петропавлівська Борщагівка, вул. Соборна,
                        2В
                      </b>
                    </p>
                    <p>
                      <b> телефон отримувача: 095-273-07-41 </b>
                    </p>
                    <p>
                      <b> оплата отримувачем по безготівковій формі </b>
                    </p>
                  </ul>
                </v-col>
                <v-col cols="12" lg="10">
                  <iframe
                    src="https://www.google.com/maps/d/embed?mid=1gA2BLKqBqti1oEG-pYzRP5iIJGw2GVI&ehbc=2E312F&noprof=1"
                    style="
                      height: 750px;
                      width: 100%;
                      position: relative;
                      overflow: hidden;
                      transform: translateZ(0px);
                      background-color: rgb(229, 227, 223);
                    "
                    width="640"
                    height="480">
                  </iframe>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-main>
  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "./datepicker_locale_ua";
import { mask } from "vue-the-mask";
import serial from "@/components/utils/serial/Index";

export default {
  directives: {
    mask
  },
  data() {
    return {
      items: ["Зареєструвати гарантію", "Пошук Сервіс-центрів"],
      drawer: false,
      tab: null,
      artikul: "",
      artikuls: [],
      modelFounded: false,
      HTTP_wo_auth: undefined,
      buyDate: "",
      fio: "",
      tel: "",
      email: "",
      buyPlaces: [],
      buyPlace: null,
      seller: "",
      componentsArray: [serial],
      serials: [{ Serial: "" }],
      dataSecurityInfo: false,
      warrantySpecInfo: false,
      valid: true,
      dateValid: true,
      artikulRules: [
        (v) => {
          if (!v) {
            return "Артикул обов'язкове поле!";
          } else if (v.length < 7) {
            return "Поле Артикул не може бути меньшим ніж 7 символів";
          } else if (!this.model) {
            return "Артикул не знайдений";
          }
          return true;
        }
      ],
      fioRules: [
        (v) => {
          if (!v) {
            return "Прізвище Ім'я Побатькові обов'язкове поле!";
          }
          return true;
        }
      ],
      telRules: [
        (v) => {
          if (!v) return "Телефон обов'язкове поле!";
          if (v && v.length < 15) return "Введіть номер повністю";
          return true;
        }
      ],
      emailRules: [
        (v) => {
          if (!v) return true;
          return (
            (v &&
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                v
              )) ||
            "Введіть правильну E-mail адресу"
          );
        }
      ],
      buyPlacesRules: [
        (v) => {
          if (!v) {
            return "Де ви придбали товар обов'язкове поле!";
          }
          return true;
        }
      ],
      sellerRules: [
        (v) => {
          if (!v) {
            return "Продавець згідно касового чеку обов'язкове поле!";
          }
          return true;
        }
      ]
    };
  },
  components: {
    DatePicker
  },
  computed: {
    allowDelete: function() {
      return this.componentsArray.length > 1;
    },
    model() {
      let temp = "";
      if (!this.artikul) {
        return "";
      } else {
        this.artikuls.forEach((element) => {
          if (element.Artikul === this.artikul) {
            temp = element.Name;
          }
        });
      }
      return temp;
    },
    DatepickerInputClass() {
      if (this.buyDate && this.dateValid) {
        return "success-input-datepicker mx-input";
      } else if (!this.buyDate && !this.dateValid) {
        return "error-input-datepicker mx-input invalid_datepicker";
      } else {
        return "error-input-datepicker mx-input";
      }
    },
    inputColorAndClassTel() {
      if (this.tel && this.tel.length > 14) {
        return { color: "green", class: "success-input" };
      } else {
        return { color: "red", class: "error-input" };
      }
    },
    inputColorAndClassEmail() {
      if (
        !this.email ||
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.email
        )
      ) {
        return { color: "green", class: "success-input" };
      } else {
        return { color: "red", class: "error-input" };
      }
    }
  },
  methods: {
    handleIconExampleShow(event) {
      event.target.parentElement.nextSibling.style.display = "block";
    },
    handleExampleShow(event) {
      event.target.nextSibling.style.display = "block";
    },
    handleExampleHide(event) {
      event.target.parentElement.style.display = "none";
    },
    clearData() {
      this.artikul = "";
      this.modelFounded = false;
      this.buyDate = "";
      this.fio = "";
      this.tel = "";
      this.email = "";
      this.buyPlace = null;
      this.seller = "";
      this.componentsArray = [serial];
      this.serials = [{ Serial: "" }];
      this.dataSecurityInfo = false;
      this.warrantySpecInfo = false;
      this.valid = true;
      this.dateValid = true;
      this.$refs.form.resetValidation();
      this.$vuetify.goTo(0);
    },
    async DoRequest() {
      if (!this.buyDate) {
        this.dateValid = false;
        return;
      }
      if (this.$refs.form.validate()) {
        let newRequest = {
          sku: this.artikul,
          model: this.model,
          buyDate: this.$moment(this.buyDate).format("DD/\MM/\YYYY"),
          fio: this.fio,
          email: this.email,
          tel: this.tel,
          buyPlaceId: this.buyPlace,
          WarrantySerials: this.serials,
          seller: this.seller
        };
        await this.HTTP_wo_auth.post("/new_warranty_request", newRequest).then(
          () => {
            this.$toastr.s("Гарантія зареєстрована");
            this.clearData();
          },
          (error) => {
            if (error.response.data.Error) {
              this.$toastr.e(error.response.data.Error);
            } else {
              this.$toastr.e(error);
            }
          }
        );
      }
    },
    handleDestroy(index) {
      this.serials.splice(index, 1);
      this.componentsArray.splice(index, 1);
    },
    add() {
      this.serials.push({ Serial: "" });
      this.componentsArray.push(serial);
    },
    inputColor(value) {
      if (value) {
        return "green";
      } else {
        return "red";
      }
    },
    inputClass(value) {
      if (value) {
        return "success-input";
      } else {
        return "error-input";
      }
    },
    disabledRange: function(date) {
      return date > new Date();
    },
    getSku() {
      this.HTTP_wo_auth.get("sku").then(
        (response) => {
          this.artikuls = response.data;
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
    getWhereToBuy() {
      this.HTTP_wo_auth.get("buy_places").then(
        (response) => {
          this.buyPlaces = response.data;
        },
        (error) => {
          if (error.response.data.Error) {
            this.$toastr.e(error.response.data.Error);
          } else {
            this.$toastr.e(error);
          }
        }
      );
    },
    setupAxios() {
      if (process.env.NODE_ENV === "production") {
        axios.defaults.baseURL = "/";
      } else {
        axios.defaults.baseURL = "http://localhost:8095/";
      }
      axios.defaults.timeout = 10000;
      this.HTTP_wo_auth = axios.create({});
    },
    artikulInputMask() {
      this.artikul = this.artikul.replace(/[^0-9|-]/g, "");
      if (this.artikul.length > 10) {
        this.artikul = this.artikul.substring(0, 10);
      }
    }
  },
  created() {
    this.setupAxios();
    this.getSku();
    this.getWhereToBuy();
  }
};
</script>

<style lang="css" scoped>
.bw {
  background: white;
}

.p-0 {
  padding: 0;
}

.h97 {
  height: 97px;
}

.logo {
  max-height: 48px;
}

.navbar {
  height: 52.5px !important;
  width: 100%;
  background: #d50c1f !important;
  box-shadow: inherit !important;
  -webkit-box-shadow: inherit !important;
}

>>> .v-toolbar__content {
  height: 52.5px !important;
}

>>> .v-tab:first-child {
  margin-left: 0 !important;
}

>>> .v-tab > .v-ripple__container {
  color: black;
}

>>> .v-tab--active {
  background: #a80707;
}

>>> .v-tab {
  color: white !important;
  font-weight: unset;
}

>>> .v-subheader {
  font-size: large;
  font-weight: bold;
}

>>> .mx-input:hover,
>>> .mx-input:focus {
  border-color: unset;
}

>>> .success-input-datepicker:hover,
>>> .success-input-datepicker:focus {
  color: green !important;
}

>>> .error-input-datepicker:hover,
>>> .error-input-datepicker:focus {
  color: red !important;
}

>>> .invalid_datepicker {
  color: red !important;
  border-color: red;
  border-width: 2px;
}

>>> .success-input:hover fieldset {
  color: green !important;
}

>>> .error-input:hover fieldset {
  color: red !important;
}

.hidden-txt {
  font-weight: bold;
  z-index: 99;
}

.hidden-img {
  display: none;
  position: fixed;
  z-index: 2;
  top: 20%;
  left: 40%;
}

/*.hidden-txt:hover ~ .hidden-img {*/
/*  display: block;*/
/*  position: fixed;*/
/*  z-index: 2;*/
/*  top: 20%;*/
/*  left: 40%;*/
/*}*/
</style>
